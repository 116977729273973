export const BACKEND_ORGANIZATIONS = "/organizations";
export const BACKEND_ORGANIZATION_WITH_USERS = "/organization_with_users";
export const BACKEND_APPS = "/apps";
export const BACKEND_APP_GROUPS = "/app_groups";
export const BACKEND_HUB_APPS = "/hub/apps";
export const BACKEND_GROUPS = "/groups";
export const BACKEND_USERS = "/users";
export const BACKEND_NOTIFICATIONS = "/notifications";
export const BACKEND_APP_GROUPS_AND_ORGANIZATION_APPS =
  "/app_groups_and_organization_apps";
export const BACKEND_ORGANIZATION_USERS_AND_GROUP_USERS =
  "/organization_users_and_group_users";
export const BACKEND_ORGANIZATION_WITH_APPS = "/organization_with_apps";
export const BACKEND_JOIN_USER_TO_GROUP = "/join_user_to_group";
export const BACKEND_CUSTOM_OVERRIDES = "/custom_overrides";
export const BACKEND_PRODUCTION_LINES = "/production-lines";
export const BACKEND_PAGED_USERS = "/paged_users";
export const BACKEND_FILTERED_USERS = "/filtered_users";

export const FRONTEND_ROOT = "/";
export const FRONTEND_COMPANIES = "/companies";
export const FRONTEND_APPLICATIONS = "/applications";
export const FRONTEND_USERS = "/users";
export const FRONTEND_PRODUCTION_LINES = "/production-lines";

export const FI = "FI";
export const EN = "EN";

const VERSION = "0.8.8";
export const FRONTEND_DEV_VERSION = `frontend/dev/${VERSION}`;
export const FRONTEND_TEST_VERSION = `frontend/test/${VERSION}`;
export const FRONTEND_PROD_VERSION = `frontend/prod/${VERSION}`;

export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";
export const CONTENT_TYPE = "Content-Type";
export const APPLICATION_JSON = "application/json";

export const VALID_COMPANY_PATTERN_REGEX = /^[\w\s+=,.@-]+$/i;
export const VALID_PROPERTY_NAME_REGEX = /^[a-zA-Z0-9$_]+$/;
export const VALID_GROUP_NAME_REGEX = /^[\p{L}\p{M}\p{S}\p{N}\p{P}]+$/u;
export const QUOTES_REGEX = /["'´`]/g;
export const VALID_EMAIL_REGEX = /\S+@\S+\.\S+/;

export const KEY = "key";
export const VALUE = "value";
export const KEY_VALUES = "key_values";
export const GROUPS = "groups";
export const GROUP_NAME = "name";
export const GROUP_DESCRIPTION = "description";
export const ORGANIZATION_APP_GROUP_USERS = "organization_app_group_users";
export const ORGANIZATION_USERS = "organization_users";
export const ORGANIZATION_APPS = "organization_apps";
export const APPLICATIONS = "applications";

export const ERROR_SESSION_IS_NOT_VALID = "ERROR: Session is not valid";
export const ERROR_CONNECTION_REFUSED = "ERROR_CONNECTION_REFUSED";
export const ERROR_FETCHING_DATA = "ERROR fetching data";

export const XXXXXXXX = "XXXXXXXX";

export const ASC = "asc";
export const DESC = "desc";
export const OFF = "off";
